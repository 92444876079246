<template lang="pug">
div
  van-search(v-model="searchKey" placeholder="搜索关键词" @search="onSearch" show-action shape="round")
    template(#action='')
      div(@click='onSearch') 搜索
  //- van-swipe.my-swipe(:autoplay='3000', indicator-color='white' :lazy-render="true")
    van-swipe-item(
      v-for="(swipe,index) of swiperData" 
      :key="index"
      )
      a(:href="swipe.url ? swipe.url : ''")
        img.intro-pic(width='100%' v-lazy='swipe.pic +  `?x-oss-process=image/resize,h_512,w_${parseInt(512/(screenWidth*0.36) * screenWidth)},m_pad,limit_0`' :key='swipe.pic')
  //- van-grid(:column-num='powerNavData.length')
    van-grid-item(
      v-for="(item, index) of powerNavData" :key="index"
      :icon='item.icon'
      :text='item.text'
      :to="item.to"
      )
  van-cell(v-if='hallConfig.furlist_url' :border='false' @click='onMore(0)'  :style='`background:url(${hallConfig.furlist_url});background-size: cover;background-position: center;height: 60px;`')
  van-cell(v-else)    
    template(#title)
      span.subtitle 特价商品
    template(#default)
      span.subtitle(@click='onMore(0)') 更多>>>
  van-swipe(:autoplay='200000'  indicator-color="white" )
    van-swipe-item(v-for='(sp,index) in Math.ceil(specialGoods.length/4)' :key='index')
      van-grid(:column-num='4' :border='false' :square='false')
        van-grid-item(
          v-for="(good, sindex) of specialGoods.slice(index*4,index*4+4)"
          :key="sindex"
          :to="good.to"
          )
          template(#default)
            van-image(:src="good.image + '?x-oss-process=image/resize,h_512,w_512,m_pad,limit_0'" width="20vw" height="20vw" radius='4' style='')
      //- img(style='height:20vh;width:20vh;' v-lazy='img.image +  `?x-oss-process=image/resize,h_${parseInt(screenWidth*0.2)},w_${parseInt(screenWidth*0.2 )},m_pad,limit_0`' :key='img.image' @click="$router.push({name: 'detail', query: {id:img.id}})")

  van-cell(style='background-color:rgb(245,245,245);' :border='false')
    template(#title)
      span.subtitle 材质分类
    template(#default)
      span.subtitle(@click='onMore(1)') 更多>>>
  van-grid(:column-num='4' square :border='false')
    van-grid-item(
      v-for="(good, index) of matTypes.slice(0,4)"
      :key="index"
      :to="good.to"
    )
      template(#icon)
        van-image(style="" radius='12' :src="good.image + `?x-oss-process=image/resize,h_512,w_512,m_pad,limit_0`" width="18vw" height="18vw")
      template(#text)
        span.van-grid-item__text(:title='good.name') 
          span {{good.name}}

  van-cell(style='background-color:rgb(245,245,245);' :border='false')
    template(#title)
      span.subtitle 空间分类
    template(#default)
      span.subtitle(@click='onMore(2)') 更多>>>
  van-grid(:column-num='4' square :border='false')
    van-grid-item(
      v-for="(good, index) of spaceTypes.slice(0,4)"
      :key="index"
      :to="good.to"
      )
      template(#icon)
        van-image(style="" :radius='12' :src="good.image + `?x-oss-process=image/resize,h_512,w_512,m_pad,limit_0`" width="18vw" height="18vw")
      template(#text)
        span.van-grid-item__text(:title='good.name') 
          span {{good.name}}
 
  van-divider(:style="{ color: '#969799', borderColor: '#969799', padding: '12px 75vw 12px 0px', margin:'0px' }") 新品推荐
  van-swipe(:autoplay='3000' style='height:20vh' indicator-color="white")
    van-swipe-item(v-for='(img,index) in imageList' :key="index")
      img(style='height:20vh;width:100%;' v-lazy='img.image +  `?x-oss-process=image/resize,h_${parseInt(screenHeight*0.2)},w_${parseInt(screenWidth)},m_pad,limit_0`' :key='img.image' @click="$router.push({name: 'detail', query: {id:img.id}})")
  van-divider(:style="{ color: '#969799', borderColor: '#969799', padding: '12px 75vw 12px 0px', margin:'0px' }") 热门商品
  van-grid(:column-num='2' square)
    van-grid-item.dp-grid(
      v-for="(good, index) of goods"
      :key="index"
      :to="good.to"
      )
      template(#default)
        van-image(:src="good.image + previewSize" width="38vw" height="38vw" radius='8')
        span.van-grid-item__text(:title='good.name') 
          span {{good.name}}
  van-row(type="flex" justify="center")
    van-col(:span="5")
      van-button(type="info" size="small" plain block :to="{name: 'search'}") 更多商品
  loading-view(:value="loadingState")

</template>

<script>
import { previewSize, bannerSize } from '../../lib/helpers/OssImageHelper'
let specialPromise = null   // 特价商品仅获取一次 避免重复获取
const typesTT = require('../../../docs/商品分类数格式.json')

export default {
  data() {
    return {
      loadingState: false,
      previewSize,
      bannerSize,
      searchKey: '',
      swiperData: [
        {
          pic: '',
          url: ''
        }
      ],
      gridNavData: [
        {
          icon: 'photo-o',
          text: '分类查询',
          to: {
            name: 'category'
          }
        },
        {
          icon: 'search',
          text: '全部商品',
          to: {
            name: 'search'
          }
        },
        {
          icon: 'like-o',
          text: '我的收藏',
          power: true,
          to: {
            name: 'collect'
          }
        },
        {
          icon: 'user-o',
          text: '个人中心',
          to: {
            name: 'person'
          }
        }
      ],
      // 热门商品
      imageList: [
        // 'https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg',
        // 'https://img.yzcdn.cn/public_files/2017/10/24/1791ba14088f9c2be8c610d0a6cc0f93.jpeg'
      ],
      // 新品推荐
      goods: [
        // {
        //   name: '商品名称',
        //   image: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        //   to: {
        //     name: 'detail',
        //     query: { id: '1' }
        //   }
        // },
      ],
      specialGoods: [],     // 特价商品
      specialGoodIds: [],     // 所有特价商品
      inited: false,
      types: [],
      matTypes: [],
      spaceTypes: []
    }
  },
  computed: {
    screenWidth() {
      return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    },
    screenHeight() {
      return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    },
    devicePixelRatio() {
      return window.devicePixelRatio
    },
    hallData() {
      return this.$store.state.hallData
    },
    // 必须登录且已经注册为公司销售员
    power() {
      // return this.$store.getters.isLogined() && this.$store.state.userInfo.sell_users
      return this.$store.getters.isLogined() && this.$store.state.userInfo.sell_users && this.$store.state.userInfo.sell_users.state == "1" 
    },
    powerNavData() {
      if(this.power) {
        return this.gridNavData
      } else {
        return this.gridNavData.filter(p=> !p.power)
      }
    },
    hallConfig() {
      return this.$store.state.hallConfig
    },
  },
  watch: {
    async hallData() {
      await this.SplitHallData()
      this.inited = true
    }
  },
  methods: {
    onSearch () {
      this.$router.push({
        name: 'search',
        query: {
          searchKey: this.searchKey
        }
      })
    },
    async SplitHallData() {
      if (Object.keys(this.hallData).length === 0) 
        return
      
      const info = this.hallData.find(i => i.isActive === true)

      // 这里不用await，每个模块各自加载
      // 轮播
      this.swiperData = info.header.find(h => h.name === '首页')
      .list.find(l => l.link === 'banner')
      .banner
      
      // 新品
      this.imageList = info.header.find(h => h.name === '首页')
      .list.find(l => l.link === 'newgoods')
      .goods
      .filter((g) => {
        return g.state_type == 'on'
      })
      .map((item) => {
        // return item.fur_img
        return {
          image: item.fur_img,
          name: item.fur_name,
          id: item.id,
          to: {
            name: 'detail',
            query: { id: item.id }
          }
        }
      })

      // 推荐商品
      // TODO: 后台配置的前置id需要配合上
      this.goods = info.header.find(h => h.name === '首页')
      .list.find(l => l.link === 'recommendgoods')
      .goods.map((item) => {
        return {
          image: item.fur_image,
          name: item.fur_name,
          id: item.id,
          to: {
            name: 'detail',
            query: {
              id: item.id
            }
          }
        }
      })

      this.specialGoodIds = info.header.find(h => h.name === '商品').list.find(l => l.link === 'furlist').goods
      this.specialGoodIds.sort((a, b) => {
        return parseInt(b.sales) - parseInt(a.sales)
      })
      this.specialGoodIds = this.specialGoodIds.map(({id}) => id)
      let ids = this.specialGoodIds
      let tempGoods = await this.getSpecialFurnitures(ids)
      this.specialGoods = tempGoods.map((item) => {
        return {
          image: item.fur_image,
          name: item.fur_name,
          id: item.id,
          to: {
            name: 'detail',
            query: {
              id: item.id
            }
          }
        }
      })
    },

    // 获取特价商品
    async getSpecialFurnitures(ids) {
      if(ids && ids.length>0) {
        if (!specialPromise) {
          let params = {
            where: {
              com_id_poi_companys: this.$store.state.routeInfo.comId,
              user_poi_users: '0',
              st_id_poi_company_stores: '0',
              dealer_id_poi_company_dealer: '0',
              fur_states: 'up',
              id: {
                $in: ids
              }
            },
            keys: 'id, fur_name,fur_image',
          }
          specialPromise = this.$api.GetFurniture(params)
        }
        let res = await specialPromise
        return res.data.items
      } else {
        return []
      }
    },

    onMore(type) {
      if (type == 0) {
        this.$router.push({
          name: 'search',
          params: {
            ids: this.specialGoodIds,
            type: 'furniture'
          }
        })
      } else if (type == 1) {
        this.$router.push({
          name: 'category',
          params: {
            index: 2,
            type: 'category'
          }
        })
      } else if (type == 2) {
        this.$router.push({
          name: 'category',
          params: {
            index: 1,
            type: 'category'
          }
        })
      }
    }
  },
  mounted() {
    this.types = typesTT
    this.matTypes = this.types[2].children.map((item) => {
      return {
        ...item,
        to: {
          name: 'search',
          query: item.query
        }
      }
    })
    this.spaceTypes = this.types[1].children.map((item) => {
      return {
        ...item,
        to: {
          name: 'search',
          query: item.query
        }
      }
    })
  }, 
  async activated() {
    this.loadingState = true
    if (!this.inited) {
        await this.SplitHallData()
        this.inited = true
    }
    this.loadingState = false
    // 如果要修改顶部导航，就把下面这段on-nav的事件打开，会发送给default.vue中的van-nav-bar接收
    // this.$emit('on-nav', {
    //   leftText: '返回',
    //   leftArrow: true,
    //   rightText: '注册',
    //   onClickRight: () =>{
    //     this.$router.push({
    //       name: 'register'
    //     })
    //   },
    //   onClickLeft: () => {
    //     this.$router.go(-1)
    //   }
    // })
  }
}
</script>

<style lang="less" scoped>
.my-swipe {
  height:36vw;
  // .van-swipe-item {
  // /* color: #fff; */
  // /* font-size: 20px; */
  // /* line-height: 200px; */
  // /* text-align: center; */
  // /* background-color: #39a9ed; */
  // }
}
.intro-pic {
  width: 100%;
  height:36vw;
  display: block;
}
.subtitle {
  font-size: 14px;
  line-height: 14px;
  color: rgb(64, 64, 65);
}
.tejia {
  // background-color: white;
  height: 60px;
  background-size: cover;
  background-position: center;
  // background-image: url('http://cdn.dpjia.com/files/uploads/images/4891844b7f59ea3fd2838256a996eefa.jpg');
  // background-repeat: repeat-x;
}
.dp-grid {
  .van-grid-item__content {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .van-grid-item__text {
    width: 100%;
    margin:auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align:center;
    vertical-align: middle;
  }
}

</style>